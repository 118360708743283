import React from 'react';
import './style.css'
import amenidades from '../../assets/apartament/Amenidades.png'
import Logo360 from '../../assets/apartament/360.svg'
import logo from '../../assets/logo.png'
import aria from '../../assets/apartament/aria/portada.png'
import mara from '../../assets/apartament/mara/portada.png'
import zatti from '../../assets/apartament/zatti/zatti.png'
import nero from '../../assets/apartament/nero/portada.png'

import ariaName from '../../assets/apartament/aria/Aria.svg'
import maraName from '../../assets/apartament/mara/Mara.svg'
import zattiName from '../../assets/apartament/zatti/Zatti.svg'
import neroName from '../../assets/apartament/nero/Nero.svg'

import ariaImage from '../../assets/apartament/aria/Aria.png'
import maraImage from '../../assets/apartament/mara/Mara.png'
import zattiImage from '../../assets/apartament/zatti/Zatti2.png'
import neroImage from '../../assets/apartament/nero/NERO.png'

import aria3dImage from '../../assets/apartament/3D/ARIA 3D.png'
import mara3dImage from '../../assets/apartament/3D/MARA 3D.png'
import zatti3dImage from '../../assets/apartament/3D/ZATTI 3D.png'
import nero3dImage from '../../assets/apartament/3D/NERO 3D.png'

import amenidad1 from '../../assets/apartament/amenidades/A1_SUM.png'
import amenidad2 from '../../assets/apartament/amenidades/A2_SUM.png'

import ScrollAnimation from 'react-animate-on-scroll';
import casaclub2 from '../../assets/house/amenidades/casaclub2.png'
import $ from 'jquery';
import { Parallax } from "react-parallax";
import AnimatedButton from '../reusable/AnimatedButton';

class HouseFull extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      selectedHouse: null,
      flagAmenidades: 1,
      amenidades: [
        amenidad1,
        amenidad2
      ],
      amenidadesName: [
        "OFICINAS",
        "GIMNASIO"
      ],
      size: '',
      plano3DAria: [
        aria3dImage,
      ],
      plano3DMara: [
        mara3dImage,
      ],
      plano3DZatti: [
        zatti3dImage,
      ],
      plano3DNero: [
        nero3dImage,
      ],
      plano2DAria: [
        ariaImage,
      ],
      plano2DMara: [
        maraImage,
      ],
      plano2DZatti: [
        zattiImage,
      ],
      plano2DNero: [
        neroImage,
      ],
    };
  }
  componentDidMount() {
    // My attempts to always have carousel begin at index 0 on show event
    $('#largeModal').on('show.bs.modal', function () {
      $('#carousel').carousel(0);
    });
    $('#largeModal').on('show.bs.modal', function () {
      $('#myCarousel').carousel(0);
    });
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div>
        <div
          className='modal fade'
          id='largeModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='basicModal'
          aria-hidden='true'
        >
          {
            this.state.flagAmenidades === 1 ?
              <div className='modal-dialog modal-lg modalSize'>
                <div className={'modal-content '} style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <div className='modal-body'>
                    {/* Carousel */}
                    <div
                      id='myCarousel'
                      className='carousel slide'
                      data-ride='carousel'
                    >
                      <div className='carousel-inner' style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'white' }}>
                        {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                          <div
                            key={index}
                            className={
                              index === 0 ? 'carousel-item active' : 'carousel-item'
                            }
                          >
                            {
                              this.state.flagAmenidades === 1 ?
                                <div>
                                  <img
                                    style={{ width: '100%' }}
                                    className={item.className}
                                    src={item}
                                    alt={item.alt}
                                  />
                                  <h1 style={{ color: 'white' }}>
                                    {this.state.amenidadesName[index]}
                                  </h1>
                                </div>
                                :
                                <img
                                  style={{ width: this.state.size === 'big' ? '100%' : '100%' }}
                                  className={item.className}
                                  src={item}
                                  alt={item.alt}
                                />
                            }

                          </div>
                        ))}
                      </div>
                      <a
                        className='carousel-control-prev'
                        href='#myCarousel'
                        role='button'
                        data-slide='prev'
                        style={{ left: '-100px' }}
                      >
                        <span
                          className='carousel-control-prev-icon'
                          aria-hidden='true'
                        >
                          {' '}
                        </span>
                        <span className='sr-only'>Previous</span>
                      </a>
                      <a
                        className='carousel-control-next'
                        href='#myCarousel'
                        role='button'
                        data-slide='next'
                        style={{ right: '-100px' }}
                      >
                        <span
                          className='carousel-control-next-icon'
                          aria-hidden='true'
                        ></span>
                        <span className='sr-only'>Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className='modal-dialog modal-lg'>
                <div className={'modal-content'} style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'white' }}>
                  <div className='modal-body'>
                    {/* Carousel */}
                    <div
                      id='myCarousel'
                      className='carousel slide'
                      data-ride='carousel'
                    >
                      {/* <ol className='carousel-indicators'>
                                                <li
                                                    data-target='#myCarousel'
                                                    data-slide-to='0'
                                                    className='active'
                                                ></li>
                                                <li data-target='#myCarousel' data-slide-to='1'></li>
                                                <li data-target='#myCarousel' data-slide-to='2'></li>
                                            </ol> */}
                      <div className='carousel-inner' style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'white' }}>
                        {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                          <div
                            key={index}
                            className={
                              index === 0 ? 'carousel-item active' : 'carousel-item'
                            }
                          >
                            {
                              this.state.flagAmenidades === 1 ?
                                <img
                                  style={{ width: '120%' }}
                                  className={item.className}
                                  src={item}
                                  alt={item.alt}
                                />
                                :
                                <img
                                  style={{ width: this.state.size === 'big' ? '100%' : '60%' }}
                                  className={item.className}
                                  src={item}
                                  alt={item.alt}
                                />
                            }

                          </div>
                        ))}
                      </div>
                      <a
                        className='carousel-control-prev'
                        href='#myCarousel'
                        role='button'
                        data-slide='prev'
                        style={{ left: '-100px' }}
                      >
                        <span
                          className='carousel-control-prev-icon'
                          aria-hidden='true'
                        >
                          {' '}
                        </span>
                        <span className='sr-only'>Previous</span>
                      </a>
                      <a
                        className='carousel-control-next'
                        href='#myCarousel'
                        role='button'
                        data-slide='next'
                        style={{ right: '-100px' }}
                      >
                        <span
                          className='carousel-control-next-icon'
                          aria-hidden='true'
                        ></span>
                        <span className='sr-only'>Next</span>
                      </a>
                    </div>
                  </div>
                  {/*  <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div> */}
                </div>
              </div>
          }


        </div>
        <div >
          <ScrollAnimation animateIn="fadeIn" className='d-none d-lg-block'>
            <div className='apartamentHouseFull'>
              <img className='logoAmenidadesApto' src={logo} alt='logoEVERO' />
              <h1 className='apartamenttitle'>
                APARTAMENTOS
              </h1>
            </div>
          </ScrollAnimation>
          <div animateIn="fadeIn" className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
            <div className='apartamentHouseFull'>
              <img className='logoAmenidadesApto' src={logo} alt='logoEVERO' />
              <h1 className='apartamenttitle'>
                APARTAMENTOS
              </h1>
            </div>
          </div>
          <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
            <div className='apartamentdiv1House'>
              <div className='apartamenttext1House'>
                <p >
                  En el lado sur de Evero se desarrollarán 7 torres de apartamentos que tendrán una
                  presencia distintiva y un papel fundamental en darle vida a esta nueva comunidad llamada Izaya.
                  En total el proyecto contara con un total de 1,351 unidades las cuales se distribuyen en
                  4 diferentes modelos que se ajustan a las diferentes necesidades de sus futuros residentes.
                  Las torres están orientadas específicamente para aprovechar las vistas a los volcanes y el lago
                  de Amatitlán al sur y de la ciudad de Guatemala al norte. El diseño respetará su contexto e
                  incluirá espacios comunes para el bienestar de sus residentes. La arquitectura de las torres es
                  sensible a la escala humana y pronto serán una nueva adición en el horizonte de San Miguel Petapa.
                </p>
              </div>
            </div>
          </ScrollAnimation>

          <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
            <div className='apartamentdiv1House'>
              <div className='apartamenttext1House'>
                <p >
                  En el lado sur de Evero se desarrollarán 7 torres de apartamentos que tendrán una
                  presencia distintiva y un papel fundamental en darle vida a esta nueva comunidad llamada Izaya.
                  En total el proyecto contara con un total de 1,351 unidades las cuales se distribuyen en
                  4 diferentes modelos que se ajustan a las diferentes necesidades de sus futuros residentes.
                  Las torres están orientadas específicamente para aprovechar las vistas a los volcanes y el lago
                  de Amatitlán al sur y de la ciudad de Guatemala al norte. El diseño respetará su contexto e
                  incluirá espacios comunes para el bienestar de sus residentes. La arquitectura de las torres es
                  sensible a la escala humana y pronto serán una nueva adición en el horizonte de San Miguel Petapa.
                </p>
              </div>
            </div>
          </div>
          <div className='apartamentHouseFullLast'>
            <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} >
              <a className='removeStyle' href='https://www.arq-3d.com/EveroApartamentos/home.html'>
                <img src={Logo360} className='logoAmenidadesApto' alt='logoEVERO' />
                <h1 className='apartamenttitle'>
                  Modelo 360°
                </h1>
              </a>
            </ScrollAnimation>
          </div>

          {/* Modelo Aria */}
          <div id='aria'>
            {/* Nombre modelo */}
            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
              <div className='apartamentModelo'>
                <img className='logo360 imageName' src={ariaName} alt='logoEVERO' />
              </div>
            </ScrollAnimation>
            {/* Fotos modelo */}
            <div className='DivFotosComplete'>
              <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                <div className='FotosModeloDivImage'>
                  <img className='fotoModelo' src={mara} alt='imageEVERO' />
                </div>
              </ScrollAnimation>
              <div className='FotosModeloDiv'>
                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                  <div className='d-none d-lg-block'>
                    <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                      - 2 Habitaciones
                    </p>
                    <p className='apartamenttextModelo'>
                      - Sala
                    </p>
                    <p className='apartamenttextModelo'>
                      - Comedor
                    </p>
                    <p className='apartamenttextModelo'>
                      - Cocina
                    </p>
                    <p className='apartamenttextModelo'>
                      - Lavandería
                    </p>
                    <p className='apartamenttextModelo'>
                      - 2 Baños
                    </p>
                  </div>
                  <div className='d-block d-lg-none'>
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                      <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                        - 2 Habitaciones
                      </p>
                      <p className='apartamenttextModelo'>
                        - Sala
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className='apartamenttextModelo'>
                        - Comedor
                      </p>
                      <p className='apartamenttextModelo'>
                        - Cocina
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                      <p className='apartamenttextModelo'>
                        - Lavandería
                      </p>
                      <p className='apartamenttextModelo'>
                        - 2 Baños
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
                <div>
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano2DAria,
                      size: 'small',
                      flagAmenidades: 0
                    })}
                    text='Plano 2D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano3DAria,
                      size: 'big',
                      flagAmenidades: 0
                    })}
                    text='Plano 3D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => window.open(
                      'https://www.arq-3d.com/EveroApartamentos/ModeloAria.html',
                      '_blank'
                    )}
                    text='Modelo 360°'
                    dataTarget=''
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Modelo Mara */}
          <div id='mara'>
            {/* Nombre modelo */}
            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
              <div className='apartamentModelo'>
                <img className='logo360 imageName' src={maraName} alt='logoEVERO' />
              </div>
            </ScrollAnimation>
            {/* Fotos modelo */}
            <div className='DivFotosComplete'>
              <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                <div className='FotosModeloDivImage'>
                  <img className='fotoModelo' src={aria} alt='imageEVERO' />
                </div>
              </ScrollAnimation>
              <div className='FotosModeloDiv'>
                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                  <div className='d-none d-lg-block'>
                    <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                      - 3 Habitaciones
                    </p>
                    <p className='apartamenttextModelo'>
                      - Sala
                    </p>
                    <p className='apartamenttextModelo'>
                      - Comedor
                    </p>
                    <p className='apartamenttextModelo'>
                      - Cocina
                    </p>
                    <p className='apartamenttextModelo'>
                      - Lavandería
                    </p>
                    <p className='apartamenttextModelo'>
                      - 2 Baños
                    </p>
                  </div>
                  <div className='d-block d-lg-none'>
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                      <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                        - 3 Habitaciones
                      </p>
                      <p className='apartamenttextModelo'>
                        - Sala
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className='apartamenttextModelo'>
                        - Comedor
                      </p>
                      <p className='apartamenttextModelo'>
                        - Cocina
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                      <p className='apartamenttextModelo'>
                        - Lavandería
                      </p>
                      <p className='apartamenttextModelo'>
                        - 2 Baños
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
                <div>
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano2DMara,
                      size: 'small',
                      flagAmenidades: 0
                    })}
                    text='Plano 2D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano3DMara,
                      size: 'big',
                      flagAmenidades: 0
                    })}
                    text='Plano 3D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => window.open(
                      'https://www.arq-3d.com/EveroApartamentos/ModeloMara.html',
                      '_blank'
                    )}
                    text='Modelo 360°'
                    dataTarget=''
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Modelo Zatti */}
          <div id='zatti'>
            {/* Nombre modelo */}
            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
              <div className='apartamentModelo'>
                <img className='logo360 imageName' src={zattiName} alt='logoEVERO' />
              </div>
            </ScrollAnimation>
            {/* Fotos modelo */}
            <div className='DivFotosComplete'>
              <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                <div className='FotosModeloDivImage'>
                  <img className='fotoModelo' src={zatti} alt='imageEVERO' />
                </div>
              </ScrollAnimation>
              <div className='FotosModeloDiv'>
                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                  <div className='d-none d-lg-block'>
                    <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                      - 3 Habitaciones
                    </p>
                    <p className='apartamenttextModelo'>
                      - Sala
                    </p>
                    <p className='apartamenttextModelo'>
                      - Comedor
                    </p>
                    <p className='apartamenttextModelo'>
                      - Cocina
                    </p>
                    <p className='apartamenttextModelo'>
                      - Lavandería
                    </p>
                    <p className='apartamenttextModelo'>
                      - 3 Baños
                    </p>
                  </div>
                  <div className='d-block d-lg-none'>
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                      <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                        - 3 Habitaciones
                      </p>
                      <p className='apartamenttextModelo'>
                        - Sala
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className='apartamenttextModelo'>
                        - Comedor
                      </p>
                      <p className='apartamenttextModelo'>
                        - Cocina
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                      <p className='apartamenttextModelo'>
                        - Lavandería
                      </p>
                      <p className='apartamenttextModelo'>
                        - 3 Baños
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
                <div>
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano2DZatti,
                      size: 'small',
                      flagAmenidades: 0
                    })}
                    text='Plano 2D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano3DZatti,
                      size: 'big',
                      flagAmenidades: 0
                    })}
                    text='Plano 3D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => window.open(
                      'https://www.arq-3d.com/EveroApartamentos/ModeloZatti.html',
                      '_blank'
                    )}
                    text='Modelo 360°'
                    dataTarget=''
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Modelo Nero */}
          <div id='nero'>
            {/* Nombre modelo */}
            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
              <div className='apartamentModelo'>
                <img className='logo360 imageName' src={neroName} alt='logoEVERO' />
              </div>
            </ScrollAnimation>
            {/* Fotos modelo */}
            <div className='DivFotosComplete'>
              <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                <div className='FotosModeloDivImage'>
                  <img className='fotoModelo' src={nero} alt='imageEVERO' />
                </div>
              </ScrollAnimation>
              <div className='FotosModeloDiv'>
                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                  <div className='d-none d-lg-block'>
                    <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                      - 4 Habitaciones
                    </p>
                    <p className='apartamenttextModelo'>
                      - 2 Salas
                    </p>
                    <p className='apartamenttextModelo'>
                      - Comedor
                    </p>
                    <p className='apartamenttextModelo'>
                      - Cocina
                    </p>
                    <p className='apartamenttextModelo'>
                      - 4 Baños
                    </p>
                    <p className='apartamenttextModelo'>
                      - Lavandería
                    </p>
                    <p className='apartamenttextModelo'>
                      - Área de Servicio
                    </p>
                    <p className='apartamenttextModelo'>
                      - Baño de Visitas
                    </p>
                  </div>
                  <div className='d-block d-lg-none'>
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                      <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                        - 4 Habitaciones
                      </p>
                      <p className='apartamenttextModelo'>
                        - 2 Salas
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className='apartamenttextModelo'>
                        - Comedor
                      </p>
                      <p className='apartamenttextModelo'>
                        - Cocina
                      </p>

                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className='apartamenttextModelo'>
                        - 4 Baños
                      </p>
                      <p className='apartamenttextModelo'>
                        - Lavandería
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>

                      <p className='apartamenttextModelo'>
                        - Área de Servicio
                      </p>
                      <p className='apartamenttextModelo'>
                        - Baño de Visitas
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
                <div>
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano2DNero,
                      size: 'small',
                      flagAmenidades: 0
                    })}
                    text='Plano 2D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => this.setState({
                      selectedHouse: this.state.plano3DNero,
                      size: 'big',
                      flagAmenidades: 0
                    })}
                    text='Plano 3D'
                    dataTarget='#largeModal'
                  />
                  <AnimatedButton
                    action={() => window.open(
                      'https://www.arq-3d.com/EveroApartamentos/ModeloNero.html',
                      '_blank'
                    )}
                    text='Modelo 360°'
                    dataTarget=''
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='apartamentHouseFullLast360'>
            <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
              <img src={amenidades} alt='logoEVERO' className='logoAmenidadesApto' />
              <h1 className='apartamenttitle removeStyle'
                onClick={() => this.setState({
                  flagAmenidades: 1
                })}
              >
                <button className='removeStyle'
                  onClick={() => this.setState({
                    selectedHouse: this.state.amenidades,
                    size: 'big',
                    flagAmenidades: 1

                  })}
                  data-toggle='modal'
                  data-target='#largeModal'
                >
                  Amenidades
                </button>
              </h1>
            </ScrollAnimation>
          </div>

          <div className='CasaClub d-block d-lg-none' style={{ height: '300px' }}>
            <Parallax style={{ width: '100% !important' }} bgImage={casaclub2} strength={50}>
              <div style={{ height: 300 }}>
                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                  {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}
                </ScrollAnimation>
              </div>
            </Parallax>
          </div>
          <div className='CasaClub d-none d-lg-block'>
            <Parallax style={{ height: '950px !important', width: '100% !important' }} bgImage={casaclub2} strength={180}>
              <div style={{ height: 900 }}>
                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                  {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}
                </ScrollAnimation>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    );
  }
}

export default HouseFull;
