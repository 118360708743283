import React from 'react';
import './style.css'
import logo from '../../assets/map/izaya.svg'
import map1 from '../../assets/map/map1.png'
import mapa1 from '../../assets/map/mapa1.png'
import presentacion from '../../assets/map/presentacion.png'
import presentacionmobile from '../../assets/map/presentacionmobile.png'
import apartamentos from '../../assets/map/apartamentos.png'
import casas from '../../assets/map/casas.png'
import Vas from '../../assets/map/vas.png'
import { GoogleApiWrapper, Marker } from 'google-maps-react';

class MapFull extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apartament: false,
      casas: false,
      vas: false,
      screenWidth: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }


  displayMarkers = () => {
    return <Marker
      key={0}
      id={0}
      position={{
        lat: 14.601947,
        lng: -90.5213706
      }}
      title={'EVERO'}
      name={'EVERO'}
      onClick={() => window.open("https://maps.google.com?q=14.601947,-90.5213706")}
    >
    </Marker>
  }

  handleMouseEnter = () => {
    this.setState({
      apartament: !this.state.apartament
    })
  };
  handleMouseLeave = () => {
    this.setState({
      apartament: !this.state.apartament
    })
  };

  handleMouseEnterCasas = () => {
    this.setState({
      casas: !this.state.casas
    })
  };
  handleMouseLeaveCasas = () => {
    this.setState({
      casas: !this.state.casas
    })
  };

  handleMouseEnterVas = () => {
    this.setState({
      vas: !this.state.vas
    })
  };
  handleMouseLeaveVas = () => {
    this.setState({
      vas: !this.state.vas
    })
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      screenWidth: window.innerWidth,
    });
  }

  render() {
    return (
      <div>
        <div className='MapFull'>
          <img className='imageIzaya' src={logo} alt='logoEVERO' />
        </div>
        <div className='textMap1'>
          <div className='mapaPresentacion' >
            <img className='imagePresentacion1' src={mapa1} alt='mapaEvero' />
          </div>
          <div className='mapaPresentacion d-block d-lg-none' >
            <img className='imagePresentacion' src={presentacionmobile} alt='mapaEvero' />
          </div>
          <div className='mapaPresentacion d-none d-lg-block' >
            <img className='imagePresentacion' src={presentacion} alt='mapaEvero' />
          </div>
          <div className='MapCasas'
            onMouseEnter={this.handleMouseEnterCasas}
            onMouseLeave={this.handleMouseLeaveCasas}
          >
            <p>
              CASAS
            </p>
          </div>
          <div className='MapRio'
            onMouseEnter={this.handleMouseEnterVas}
            onMouseLeave={this.handleMouseLeaveVas}
          >
            <p>
              VAS - Vía Alterna del Sur
            </p>
          </div>
          <img src={this.state.apartament ? apartamentos : this.state.casas ? casas :
            this.state.vas ? Vas : map1} alt='everoMap' />
          <div className='Mapapto'
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <p>
              APARTAMENTOS
            </p>
          </div>
        </div>

        <div className='DivMap'>
          <iframe
            title="Mapa"
            src={
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15451.309135089448!2d-90.56813917449962!3d14.49460242253798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3f24f41182b%3A0xbbc63b47dd46e3a5!2sEVERO!5e0!3m2!1ses-419!2sgt!4v1707929630321!5m2!1ses-419!2sgt"
            }
            width="100%"
            height="100%"
            style={{
              'border': 0,
            }}
            className="map"
            allowfullscreen=""
            loading="lazy" />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCWVVCxYjdLc2mYJxpqZdjZxa3QhldwOsw'
})(MapFull);


