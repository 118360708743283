import React from 'react'

import './style.css'
import ScrollAnimation from 'react-animate-on-scroll';

function AnimatedButton({
  action,
  text,
  dataTarget,
}) {
  return (
    <ScrollAnimation
      delay={100}
      animateIn="fadeIn"
      animateOnce={true}
      className='animatedDiv'
    >
      <button type='button'
        onClick={action}
        data-toggle='modal'
        data-target={dataTarget}
        className='animatedButton'
      >
        <p className='animatedButtonText'>
          {text}
        </p>
      </button>
    </ScrollAnimation>
  )
}

export default AnimatedButton