import React from 'react';
import './style.css'
import { Row, Col, Container } from 'reactstrap';
import evero from '../../assets/footer/everoLogo.svg'
import rosul from '../../assets/footer/rosul.svg'
import rosulMobile from '../../assets/footer/everoMobile.svg'
import fb from '../../assets/footer/fb.svg'
import ig from '../../assets/footer/ig.svg'
import ink from '../../assets/footer/in.svg'
import waze from '../../assets/footer/waze.svg'
import map from '../../assets/footer/map.svg'
import wa from '../../assets/footer/wa.svg'

const pathname = window.location.pathname

class HomeFull extends React.Component {

  constructor() {
    super();
    this.state = {
      waiting: false
    }
  }

  handleAnimation = () => {
    this.setState({ waiting: true });
    setTimeout(() => {
      this.setState({ waiting: false });
    }, 4500); // Espera medio segundo antes de regresar al estado original
  };

  componentDidMount() {
    this.handleAnimation()
  }


  render() {
    return (
      <Container fluid className='Footer'>
        {!this.state.waiting && pathname === '/' ?
          <Row>
            <Col md={9} sm={3}>
              <Row>

                <Col md={3} xs={8} className="d-block d-lg-none">
                  <div className='divFooterRosul'>
                    <img className='imageFooterRosul' src={rosulMobile} alt='logoFooter' />
                  </div>
                </Col>
                <Col className='d-block d-lg-none'>
                  <div className='flexredes d-block d-lg-none'>
                    <div md={2} xs={1}>
                      <a href='https://www.facebook.com/EveroGT?mibextid=2JQ9oc'>
                        <img className='imageFooterRedes' src={fb} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://instagram.com/evero.gt?igshid=OGQ5ZDc2ODk2ZA=='>
                        <img className='imageFooterRedes' src={ig} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://www.linkedin.com/showcase/izaya/'>
                        <img className='imageFooterRedes' src={ink} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://waze.com/ul/h9fxd7nhpf'>
                        <img className='imageFooterRedes' src={waze} alt='logoFooter' />
                      </a>
                    </div>

                  </div>

                </Col>


                <Col md={3} xs={4} className="d-none d-lg-block">
                  <div className='divFooterRosul'>
                    <img className='imageFooterRosul' src={rosul} alt='logoFooter' />
                  </div>
                </Col>

                <Col md={5} className="d-none d-lg-block">
                  <p className='textFooterTitle'>
                    <marker className='marker'>Showroom:</marker>  C.C. El Frutal, 2do nivel
                  </p>
                </Col>
                <Col className='FooterWA d-none d-lg-block' md={3} xs={12} >
                  <div style={{ display: 'flex' }}>
                    <a href='https://wa.me/+50259223111'>
                      <img className='waFooterLeft' src={wa} alt='wa' />
                    </a>
                    <p className='textFooterWa'>
                      5922-3111
                    </p>
                  </div>
                </Col>


              </Row>
              <Row>
                <Col md={3} xs={2}>
                </Col>
                <Col md={5} className="d-none d-lg-block">
                  <p className='textFooter'>
                    <marker className='marker'>Dirección:</marker>  10 calle 11-20. Bulevar Izaya, <br></br>
                    zona 14 de San Miguel Petapa, Guatemala
                  </p>
                </Col>
                <Col className=' d-none d-lg-block'>
                  <div className='flexredes'>
                    <div md={2} xs={2}>
                      <a href='https://www.facebook.com/EveroGT?mibextid=2JQ9oc'>
                        <img className='imageFooterRedes' src={fb} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>
                      <a href='https://instagram.com/evero.gt?igshid=OGQ5ZDc2ODk2ZA=='>
                        <img className='imageFooterRedes' src={ig} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}
                    >
                      <a href='https://www.linkedin.com/showcase/izaya/'>
                        <img className='imageFooterRedes' src={ink} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>



                      <a href='https://waze.com/ul/h9fxd7nhpf'>
                        <img className='imageFooterRedes' src={waze} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>
                      <a href='https://maps.app.goo.gl/ukw9xbAu5mPLPtEeA'>

                        <img className='imageFooterRedes' src={map} alt='logoFooter' />
                      </a>
                    </div>
                  </div>

                </Col>
                <Col className='FooterWA d-block d-lg-none' md={3} xs={12} >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a href='https://wa.me/+50259223111'>
                      <img className='waFooterLeft' src={wa} alt='wa' />
                    </a>
                    <p className='textFooterWa' style={{ textDecoration: 'none', textDecorationLine: 'none' }}>
                      5922-3111
                    </p>
                  </div>
                </Col>

              </Row>
              <Row className="d-block d-lg-none">
                <Col md={8} >
                  <p className='textFooterTitle'>
                    <marker className='marker'>Showroom:</marker>  C.C. El Frutal, 2do nivel Zona 5 de Villa Nueva
                  </p>
                </Col>
              </Row>
              <Row className="d-block d-lg-none">
                <Col md={5} >
                  <p className='textFooter'>
                    <marker className='marker'>Dirección:</marker>  10 calle 11-20. Bulevar Izaya, <br></br>
                    zona 14 de San Miguel Petapa, Guatemala
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={3} className='centerimageFooter'>

              <img className='imageFooter' src={evero} alt='logoFooter' />

            </Col>

          </Row>
          :
          <Row>
            <Col md={9} sm={3}>
              <Row>

                <Col md={3} xs={8} className="d-block d-lg-none">
                  <div className='divFooterRosul'>
                    <img className='imageFooterRosul' src={rosulMobile} alt='logoFooter' />
                  </div>
                </Col>
                <Col className='d-block d-lg-none'>
                  <div className='flexredes d-block d-lg-none'>
                    <div md={2} xs={1}>
                      <a href='https://www.facebook.com/EveroGT?mibextid=2JQ9oc'>
                        <img className='imageFooterRedes' src={fb} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://instagram.com/evero.gt?igshid=OGQ5ZDc2ODk2ZA=='>
                        <img className='imageFooterRedes' src={ig} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://www.linkedin.com/showcase/izaya/'>
                        <img className='imageFooterRedes' src={ink} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={1}>
                      <a href='https://waze.com/ul/h9fxd7nhpf'>
                        <img className='imageFooterRedes' src={waze} alt='logoFooter' />
                      </a>
                    </div>

                  </div>

                </Col>


                <Col md={3} xs={4} className="d-none d-lg-block">
                  <div className='divFooterRosul'>
                    <img className='imageFooterRosul' src={rosul} alt='logoFooter' />
                  </div>
                </Col>

                <Col md={5} className="d-none d-lg-block">
                  <p className='textFooterTitle'>
                    <marker className='marker'>Showroom:</marker>  C.C. El Frutal, 2do nivel
                  </p>
                </Col>
                <Col className='FooterWA d-none d-lg-block' md={3} xs={12} >
                  <div style={{ display: 'flex' }}>
                    <a href='https://wa.me/+50259223111'>
                      <img className='waFooterLeft' src={wa} alt='wa' />
                    </a>
                    <p className='textFooterWa'>
                      5922-3111
                    </p>
                  </div>
                </Col>


              </Row>
              <Row>
                <Col md={3} xs={2}>
                </Col>
                <Col md={5} className="d-none d-lg-block">
                  <p className='textFooter'>
                    <marker className='marker'>Dirección:</marker>  10 calle 11-20. Bulevar Izaya, <br></br>
                    zona 14 de San Miguel Petapa, Guatemala
                  </p>
                </Col>
                <Col className=' d-none d-lg-block'>
                  <div className='flexredes'>
                    <div md={2} xs={2}>
                      <a href='https://www.facebook.com/EveroGT?mibextid=2JQ9oc'>
                        <img className='imageFooterRedes' src={fb} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>
                      <a href='https://instagram.com/evero.gt?igshid=OGQ5ZDc2ODk2ZA=='>
                        <img className='imageFooterRedes' src={ig} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}
                    >
                      <a href='https://www.linkedin.com/showcase/izaya/'>
                        <img className='imageFooterRedes' src={ink} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>



                      <a href='https://waze.com/ul/h9fxd7nhpf'>
                        <img className='imageFooterRedes' src={waze} alt='logoFooter' />
                      </a>
                    </div>
                    <div md={2} xs={2}>
                      <a href='https://maps.app.goo.gl/ukw9xbAu5mPLPtEeA'>
                        <img className='imageFooterRedes' src={map} alt='logoFooter' />
                      </a>
                    </div>
                  </div>

                </Col>
                <Col className='FooterWA d-block d-lg-none' md={3} xs={12} >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a href='https://wa.me/+50259223111'>
                      <img className='waFooterLeft' src={wa} alt='wa' />
                    </a>
                    <p className='textFooterWa' style={{ textDecoration: 'none', textDecorationLine: 'none' }}>
                      5922-3111
                    </p>
                  </div>
                </Col>

              </Row>
              <Row className="d-block d-lg-none">
                <Col md={8} >
                  <p className='textFooterTitle'>
                    <marker className='marker'>Showroom:</marker>  C.C. El Frutal, 2do nivel Zona 5 de Villa Nueva
                  </p>
                </Col>
              </Row>
              <Row className="d-block d-lg-none">
                <Col md={5} >
                  <p className='textFooter'>
                    <marker className='marker'>Dirección:</marker>  10 calle 11-20. Bulevar Izaya, <br></br>
                    zona 14 de San Miguel Petapa, Guatemala
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={3} className='centerimageFooter'>

              <img className='imageFooter' src={evero} alt='logoFooter' />

            </Col>

          </Row>
        }
      </Container>
    );
  }
}

export default HomeFull;
