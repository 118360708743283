import React from 'react';
import './style.css'
import { Button } from 'reactstrap';
import image1 from '../../assets/home/image1.png'
import apartament from '../../assets/home/apartamento.png'
import image2 from '../../assets/home/image2.png'
import image3 from '../../assets/home/casa.png'
import image4 from '../../assets/home/casa1.png'
import { Parallax } from "react-parallax";
import ScrollAnimation from 'react-animate-on-scroll';

class HomeFull extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollY: 0,
      waiting: true,
    };
  }

  handleAnimation = () => {
    this.setState({ waiting: true });
    setTimeout(() => {
      this.setState({ waiting: false });
    }, 4500); // Espera medio segundo antes de regresar al estado original
  };

  componentDidMount() {
    this.handleAnimation()
  }

  render() {
    return (
      <div>
        <div >
          <div className='HomeFullFirst'   >
            <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
              <h1 className='title '>
                HORIZONTES
                <br />
                SIN LIMITES
              </h1>
            </ScrollAnimation>
            <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
              <h1 className='title '>
                HORIZONTES
                <br />
                SIN LIMITES
              </h1>
            </div>
            <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
              <p className='text'>
                Una nueva y brillante experiencia de estilo de vida.
              </p>
            </ScrollAnimation>

            <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
              <p className='text'>
                Una nueva y brillante experiencia de estilo de vida.
              </p>
            </div>


            <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>              
            <Button className='buttonApto'>
              <a href='https://public-rosul-bucket.s3.us-east-2.amazonaws.com/Evero_Presentaci%C3%B3ndigital.pdf' className='removeStyleHomeApto'
              target="_blank" rel="noopener noreferrer">
              Presentación Digital
              </a>
            </Button>
            </ScrollAnimation>

            <div className='divImagesFirst'>

              <div className='divoneImage'>
                <Parallax bgImage={image4} strength={200}>
                  <div className='flexImage' style={{ height: 980 }} >
                    {/*  <img className='imageFirst' src={image4} alt='everoImage' /> */}
                    <a href='/casas' className='buttonFirst removeStyleHome'>
                      <p className='textFirst'>
                        Casas
                      </p>
                    </a>
                  </div>
                </Parallax>
              </div>

              <div className='lineWhite'>

              </div>
              <div className='divtwoImage'>

                <Parallax bgImage={apartament} strength={200}>
                  <div className='lineWhite'>

                  </div>
                  <div style={{ height: 980 }}>
                    {/*  <img className='imageFirst' src={apartament} alt='everoImage' /> */}
                    <a a href="/apartamentos" className='buttonSecond removeStyleHome'>
                      <p className='textFirst'>
                        Apartamentos
                      </p>
                    </a>
                  </div>
                </Parallax>
              </div>
            </div>
          </div>
          <div className='parallaxphone d-none d-lg-block'>
            <Parallax bgImage={image3} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 1380 }}>

                <div className='HomeFull'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title '>
                      UN NUEVO
                      <br />
                      DESTINO
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Un hogar que combina con tu estilo de vida,
                      donde cada detalle <br className='d-none d-lg-block' /> está cuidadosamente elaborado para lograr
                      un diseño único.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <Button className='buttonHouse'>
                      <a href='/casas' className='removeStyleHome'>
                        Conoce más
                      </a>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>
          <div className='parallaxphone d-block d-lg-none'>
            <Parallax bgImage={image3} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 980 }}>

                <div className='HomeFull'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title '>
                      UN NUEVO
                      <br />
                      DESTINO
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Un hogar que combina con tu estilo de vida,
                      donde cada detalle <br className='d-none d-lg-block' /> está cuidadosamente elaborado para lograr
                      un diseño único.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <Button className='buttonHouse'>
                      <a href='/casas' className='removeStyleHome'>
                        Conoce más
                      </a>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>

          <div className='parallaxphone2 d-none d-lg-block'>
            <Parallax bgImage={image1} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 1380 }}>

                <div className='HomeFullSecond'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title'>
                      BIENVENIDOS A UN MUNDO
                      <br />
                      DE POSIBILIDADES
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Cuidadosamente diseñado con cada aspecto creado
                      para entusiasmar <br className='d-none d-lg-block' /> e inspirar a los residentes
                      con infinitas posibilidades.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <Button className='buttonApto'>
                      <a href='/ubicacion' className='removeStyleHomeApto'>
                        Conoce más
                      </a>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>
          <div className='parallaxphone d-block d-lg-none'>
            <Parallax bgImage={image1} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 980 }}>

                <div className='HomeFullSecond'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title'>
                      BIENVENIDOS A UN MUNDO
                      <br />
                      DE POSIBILIDADES
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Cuidadosamente diseñado con cada aspecto creado
                      para entusiasmar <br className='d-none d-lg-block' /> e inspirar a los residentes
                      con infinitas posibilidades.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <Button className='buttonApto'>
                      <a href='/ubicacion' className='removeStyleHomeApto'>
                        Conoce más
                      </a>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>

          <div className='parallaxphone d-none d-lg-block'>
            <Parallax bgImage={image2} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 1380 }}>

                <div className='HomeFullThird'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title'>
                      IZAYA
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Descubra una oportunidad única de vivir en un lugar emblemático de
                      Izaya, <br className='d-none d-lg-block' /> con apartamentos desde 66m² hasta 210 m² y casas desde
                      169 m² hasta 222 m²
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn">
                    <Button className='buttonUbi'>
                      <p className='buttonText'>
                        <a href='/ubicacion' className='removeStyleHome' animateOnce={true}>
                          Conoce más
                        </a>
                      </p>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>
          <div className='parallaxphone d-block d-lg-none'>
            <Parallax bgImage={image2} strength={200}>
              <div className='HomeFullSecondIMAGE' style={{ height: 980 }}>

                <div className='HomeFullThird'  >
                  <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                    <h1 className='title'>
                      IZAYA
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true}>
                    <p className='text'>
                      Descubra una oportunidad única de vivir en un lugar emblemático de
                      Izaya, <br className='d-none d-lg-block' /> con apartamentos desde 66m² hasta 210 m² y casas desde
                      169 m² hasta 222 m²
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation delay={100} animateIn="fadeIn">
                    <Button className='buttonUbi'>
                      <p className='buttonText'>
                        <a href='/ubicacion' className='removeStyleHome' animateOnce={true}>
                          Conoce más
                        </a>
                      </p>
                    </Button>
                  </ScrollAnimation>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFull;
